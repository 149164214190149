import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import '../styles/Header.css';

const Header = ({ auth, setAuth }) => {
  const [projectsDropdownOpen, setProjectsDropdownOpen] = useState(false);
  const [managersDropdownOpen, setManagersDropdownOpen] = useState(false);
  const [experienceDropdownOpen, setExperienceDropdownOpen] = useState(false);
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = (setDropdown) => {
    setDropdown((prevState) => !prevState);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuth(null);
    navigate('/');
  };

  const handleRestrictedNavigation = (event, path) => {
    if (!auth) {
      event.preventDefault();
      setShowLoginMessage(true);
      setTimeout(() => setShowLoginMessage(false), 3000);
    } else {
      navigate(path);
    }
  };

  const renderMenuItem = (label, path) => (
    <Link to={path} onClick={(e) => handleRestrictedNavigation(e, path)}>
      {label}
    </Link>
  );

  return (
    <header>
      <section className="image">
        <img src="/diamond.png" alt="logo" height={100} width={80} />
        <div className="user-role">
          {auth ? (auth === 'admin' ? 'Admin Login' : 'Guest Login') : 'Login'}
        </div>
      </section>
      <nav>
        <div className="login">
          {!auth ? (
            <Link to="/login">
              <FontAwesomeIcon icon={faUser} size="2x" />
            </Link>
          ) : (
            <button onClick={handleLogout}>
              <FontAwesomeIcon icon={faUser} size="2x" />
            </button>
          )}
        </div>
        <Link to="/">Home</Link>
       

        {/* Restricted Sections */}
        {auth && (
          <>
            <div
              className="dropdown"
              onMouseEnter={() => toggleDropdown(setProjectsDropdownOpen)}
              onMouseLeave={() => toggleDropdown(setProjectsDropdownOpen)}
            >
              <span className="dropbtn">Projects</span>
              <div className={`dropdown-content ${projectsDropdownOpen ? 'show' : ''}`}>
                {renderMenuItem('Krishna Green Midlake III', '/projects/project1')}
                {renderMenuItem('Krishna Green North Star', '/projects/project2')}
              </div>
            </div>

            {/* Admin Only Section */}
            {auth === 'admin' && (
              <div
                className="dropdown"
                onMouseEnter={() => toggleDropdown(setManagersDropdownOpen)}
                onMouseLeave={() => toggleDropdown(setManagersDropdownOpen)}
              >
                <span className="dropbtn">Option</span>
                <div className={`dropdown-content ${managersDropdownOpen ? 'show' : ''}`}>
                  {renderMenuItem('View/Download', '/download')}
                </div>
              </div>
            )}

            {renderMenuItem('Documentation', '/document')}
            {renderMenuItem('PayslipForm', '/payslipform')}
            {renderMenuItem('Make Payment', '/payment-installment')}

            <div
              className="dropdown"
              onMouseEnter={() => toggleDropdown(setExperienceDropdownOpen)}
              onMouseLeave={() => toggleDropdown(setExperienceDropdownOpen)}
            >
              <span className="dropbtn">Experience</span>
              <div className={`dropdown-content ${experienceDropdownOpen ? 'show' : ''}`}>
                {renderMenuItem('Admin', '/admin')}
                {renderMenuItem('Sales', '/experience')}
              </div>
            </div>
          </>
        )}
        <Link to="/contact">Contact</Link>
      </nav>

      {/* Login Message */}
      {showLoginMessage && (
        <div className="login-message">Please login to access the section</div>
      )}


    </header>
  );
};

export default Header;
