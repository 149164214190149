import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';

const Home = React.lazy(() => import('./components/Home'));
const Login = React.lazy(() => import('./components/Login'));
const Project1Form = React.lazy(() => import('./components/Project1Form'));
const Project2Form = React.lazy(() => import('./components/Project2Form'));
const Contact = React.lazy(() => import('./components/Contact'));
const Documentation = React.lazy(() => import('./components/Documentation'));
const DownloadPage = React.lazy(() => import('./components/DownloadPage'));
const PaymentInstallmentForm = React.lazy(() => import('./components/PaymentInstallmentForm'));
const PayslipForm = React.lazy(() => import('./components/PayslipForm'));
const Experience = React.lazy(() => import('./components/Experience'));
const Admin = React.lazy(() => import('./components/Admin'));
const PaymentDetails = React.lazy(() => import('./components/PaymentDetails'));

function App() {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const user = JSON.parse(atob(token.split('.')[1]));
        setAuth(user.role);
      } catch (error) {
        console.error("Token decode error", error);
        localStorage.removeItem('token');
        setAuth(null);
      }
    } else {
      setAuth(null);
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Header auth={auth} setAuth={setAuth} />
        <main>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={auth ? <Home /> : <Navigate to="/login" />} />
              <Route path="/login" element={<Login onLogin={setAuth} />} />
              <Route path="/projects/project1" element={auth ? <Project1Form /> : <Navigate to="/login" />} />
              <Route path="/projects/project2" element={auth ? <Project2Form /> : <Navigate to="/login" />} />
              <Route path="/payment-installment" element={auth ? <PaymentInstallmentForm /> : <Navigate to="/login" />} />
              {auth === 'admin' && (
                <Route path="/download" element={<DownloadPage />} />
              )}
              <Route path="/contact" element={<Contact />} />
              <Route path="/document" element={auth ? <Documentation /> : <Navigate to="/login" />} />
              <Route path="/payslipform" element={auth ? <PayslipForm /> : <Navigate to="/login" />} />
              <Route path="/experience" element={auth ? <Experience /> : <Navigate to="/login" />} />
              <Route path="/admin" element={auth ? <Admin /> : <Navigate to="/login" />} />
              {auth === 'admin' && (
                <Route path="/payment-details/:id" element={<PaymentDetails />} />
              )}
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
